<template>
  <div class="swiper-outer">
    <div class="swiper">
      <!-- Additional required wrapper -->
      <div ref="wrapper" class="swiper-wrapper">
        <!-- Slides -->
        <slot></slot>
      </div>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>

      <!-- If we need navigation buttons -->
      <template v-if="useNavigation">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </template>

      <!-- If we need scrollbar -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper, { Keyboard, Manipulation, Pagination, Navigation } from 'swiper'

export default {
  name: 'Swiper',
  data() {
    return {
      swiper: undefined,
      swiperElement: undefined,
      lastScrollTimestamp: 0,
    }
  },
  props: {
    activeIndex: { type: Number, default: 0 },
    updateKey: Number,
    useNavigation: Boolean,
  },
  watch: {
    updateKey() {
      if (this.swiper)
        this.$nextTick(() => {
          this.swiper.update()
          this.swiper.slideTo(this.activeIndex)
        })
    },
  },
  mounted() {
    this.swiperElement = document.querySelector('.swiper-outer')
    this.setupInstance()
    this.setupEvents()
  },
  beforeUnmount() {
    this.destroyEvents()
  },
  methods: {
    setupInstance() {
      const modules = [Pagination, Manipulation, Keyboard]
      const options = {
        modules,
        threshold: 5,
        // Optional parameters
        // cssMode: true,
        initialSlide: this.activeIndex,
        direction: 'horizontal',
        spaceBetween: 16,
        slideToClickedSlide: true,
        // loop: true,
        // loopedSlides: 5,
        slidesPerView: 'auto',
        centeredSlides: true,
        mousewheelControl: true,

        // Keyboard control module
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },

        // Pagination module
        pagination: {
          el: '.swiper-pagination',
        },

        // Scrollbar module
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      }
      if (this.useNavigation) {
        modules.push(Navigation)
        options.navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      }

      this.swiper = new Swiper('.swiper', options)
    },
    setupEvents() {
      this.swiper.on('keyPress', (swiper, key) => {
        if (key === 13 || key === 32) this.$emit('confirm', swiper.activeIndex)
      })
      this.swiper.on('click', ({ clickedIndex }) => {
        const dblclick = this.activeIndex === clickedIndex
        if (dblclick) this.$emit('confirm', clickedIndex)
      })
      this.swiper.on('slideChange', swiper => {
        this.$emit('select', swiper.activeIndex)
      })
      this.swiperElement.addEventListener('wheel', this.onWheel)
    },
    onWheel({ deltaX }) {
      const timestamp = Date.now()
      if (timestamp - this.lastScrollTimestamp < 500) return

      this.lastScrollTimestamp = timestamp
      if (deltaX > 0) this.swiper.slideNext()
      else if (deltaX < 0) this.swiper.slidePrev()
    },
    destroyEvents() {
      this.swiperElement.removeEventListener('wheel', this.onWheel)
    },
  },
}
</script>

<style lang="scss">
.swiper-outer {
  display: grid;
  grid-template-columns: 1fr;
}
.swiper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 36px;
}

.swiper-pagination-bullet {
  --swiper-bullet-size: 5px;
  background: #888;
  border-radius: var(--swiper-bullet-size);
  height: var(--swiper-bullet-size);
  width: var(--swiper-bullet-size);
  transition: all 0.3s ease-in-out;
}
.swiper-pagination-bullet-active {
  background: #606060;
  width: calc(var(--swiper-bullet-size) * 3);
}

.swiper-button-prev,
.swiper-button-next {
  --size: 50px;
  background: #fff;
  height: var(--size);
  width: var(--size);
  top: calc(50% - var(--size) / 2);
  border-radius: 100%;
  --swiper-navigation-size: 20px;
  font-weight: bold;
}
</style>
